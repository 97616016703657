const fidelConfig = {
    companyName: '',
    sdkKey: '',
    programId: '',
    init: false
};

const initFidel = ({companyName, sdkKey, programId}) => {
    fidelConfig.companyName = companyName;
    fidelConfig.sdkKey = sdkKey;
    fidelConfig.programId = programId;

    fidelConfig.init = true;
}

const startFidelLink = (linkSuccessCallback) => {

    if (!fidelConfig.init) throw 'Fidel Not Initialised';

    Fidel.openForm({
        companyName: fidelConfig.companyName,
        sdkKey: fidelConfig.sdkKey,
        programId: fidelConfig.programId,
        callback(errResp, newCardResp) {

            let cardId = newCardResp ? newCardResp.id : null;

            // We may be ok with an error response if it represents an existing card. 
            // The server will determine if we can link it to this site group. 
            if (!cardId && errResp && errResp.code == 'map.already.exists' && errResp.resource) {

                const existingCardResource = errResp.resource;
                cardId = existingCardResource.replace('/v1/cards/', '');
            }

            if (cardId) {
                Fidel.closeForm();
                linkSuccessCallback(cardId);
            }

        },
        metadata: {
            platformCreated: true
        }         
      });

}

export { initFidel, startFidelLink };
