import "alpinejs";

document.documentElement.classList.remove("no-js");

// Modules
import { lazyLoadModule } from "./modules/lazyLoad";
import * as InspireFidel from "./modules/fidel";
import "./modules/account";
// import "./modules/slider";
import "./modules/carouselHero";

// Exports
lazyLoadModule();

// Global Exports
window.InspireFidel = InspireFidel;
