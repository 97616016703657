window.addEventListener("load", function (event) {
  let heroCaro = document.querySelector(".heroCaro");

  if (!heroCaro) return;

  let firstFlck = new Flickity(heroCaro, {
    lazyLoad: true,
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: false,
    autoPlay: false,
    cellAlign: "left",
  });
  var previousButton = document.querySelector(".button--previous");
  previousButton.addEventListener("click", function () {
    firstFlck.previous();
  });
  var nextButton = document.querySelector(".button--next");
  nextButton.addEventListener("click", function () {
    firstFlck.next();
  });

  firstFlck.on("select", function () {
    var target = firstFlck.selectedCell.target;
    var LBtn = document.getElementById("LB");
    var RBtn = document.getElementById("RB");
    if (target == firstFlck.cells[0].target) {
      LBtn.classList.add("disable-icon");
    } else {
      LBtn.classList.remove("disable-icon");
    }

    if (target == firstFlck.getLastCell().target) {
      RBtn.classList.add("disable-icon");
    } else {
      RBtn.classList.remove("disable-icon");
    }
  });
});

window.addEventListener("load", function (event) {
  let secondCarousel = document.querySelector(".second-carousel");

  if (!secondCarousel) return;

  let secondFlck = new Flickity(secondCarousel, {
    lazyLoad: true,
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: false,
    autoPlay: false,
    cellAlign: "left",
  });
  var previousButton = document.querySelector(".button--previous");
  previousButton.addEventListener("click", function () {
    secondFlck.previous();
  });
  var nextButton = document.querySelector(".button--next");
  nextButton.addEventListener("click", function () {
    secondFlck.next();
  });

  secondFlck.on("select", function () {
    var target = secondFlck.selectedCell.target;
    var LBtn = document.getElementById("LBtn");
    var RBtn = document.getElementById("RBtn");
    if (target == secondFlck.cells[0].target) {
      LBtn.classList.add("disable-icon");
    } else {
      LBtn.classList.remove("disable-icon");
    }

    if (target == secondFlck.getLastCell().target) {
      RBtn.classList.add("disable-icon");
    } else {
      RBtn.classList.remove("disable-icon");
    }
  });
});

window.addEventListener("load", function (event) {
  let thirdCarousel = document.querySelector(".third-carousel");

  if (!thirdCarousel) return;

  let thirdFlck = new Flickity(thirdCarousel, {
    lazyLoad: true,
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: false,
    autoPlay: false,
    cellAlign: "left",
  });
  var previousButton = document.querySelector(".button--previous");
  previousButton.addEventListener("click", function () {
    thirdFlck.previous();
  });
  var nextButton = document.querySelector(".button--next");
  nextButton.addEventListener("click", function () {
    thirdFlck.next();
  });

  thirdFlck.on("select", function () {
    var target = thirdFlck.selectedCell.target;
    var LBtn = document.getElementById("LBtn");
    var RBtn = document.getElementById("RBtn");
    if (target == thirdFlck.cells[0].target) {
      LBtn.classList.add("disable-icon");
    } else {
      LBtn.classList.remove("disable-icon");
    }

    if (target == thirdFlck.getLastCell().target) {
      RBtn.classList.add("disable-icon");
    } else {
      RBtn.classList.remove("disable-icon");
    }
  });
});
